.signup_header {
  display: flex;
  justify-content: space-between;
}

.signup_form {
  padding: 0 115px 50px 115px;
}

@media (max-width: 427px) {
  .signup_form {
    padding: 0 36px 50px 36px;
  }
}