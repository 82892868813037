.loginModal__headerContainer {
  background-color: rgba(187, 107, 217, 0.8);
  position: relative;
  z-index: 1;
}
.loginModal__carouselImageDiv {
  width: 100%;
}
.loginModal__carouselImageDiv2 {
  width: 100%;
  display: flex;
  padding-right: 5.813rem;
  justify-content: flex-end;
}
.loginModal__carouselImage {
  max-width: 23.938rem;
  max-height: 17.188rem;
}

.loginModal__submitBtn {
  margin-bottom: 0;
}

.loginModal__HeaderContainer {
  padding-left: 5.813rem;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
}

.loginModal__headerArrowContainer{
  position: absolute;
  padding: 0 20px 0 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  top: 0;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  background-color: transparent;
}
.loginModal__headertext {
  font-style: normal;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 3.75rem;
  color: #ffffff;
  line-height: 72px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.loginModal__content {
  padding: 0 11.25rem 0 11.25rem;
}
.loginModal__linkContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px 35px 50px;
}

.loginModal__btnContainer {
  display: flex;
  justify-content: flex-end;
}

.readyFX {
  display: flex;
  align-items: center;
}

.modal__plaintext {
  margin-top: 24px;
  font-size: 14px;
  color: #4f4f4f;
}

.modal__login_btn_btn {
  width: 190.24px;
  height: 40px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
}

.rightArrow {
  color: #e062ff !important;
}

.carouselArrow {
  color: white !important;
  border: 0 !important;
}
.footerLink{
  background-color: transparent !important;
  border: 0 !important;
}
@media (max-width: 767px) {
  .loginModal__content {
    padding: 0 9rem 0 9rem;
  }
  .loginModal__HeaderContainer {
    padding-left: 5rem;
  }
  .loginModal__carouselImageDiv2 {
    padding-right: 5rem;
  }
}
@media (max-width: 611px) {
  .loginModal__linkContainer {
    padding: 0 5rem 35px 5rem;
  }
  .loginModal__content {
    padding: 0 5rem 0 5rem;
  }
  .loginModal__HeaderContainer {
    padding-left: 5rem;
  }
  .loginModal__carouselImageDiv2 {
    padding-right: 5rem;
  }
  .loginModal__carouselImage {
    width: 180px;
  }
  .loginModal__carouselImageDiv2 {
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 0;
  }
  .loginModal__HeaderContainer {
    padding-left: 0;
    justify-content: center;
  }
  .loginModal__headertext {
    font-size: 50px;
  }
}
@media (max-width: 427px) {
  .loginModal__content {
    padding: 0 1.75rem 0 1.75rem;
  }
  .loginModal__linkContainer {
    padding: 50px 1.75rem 50px 1.75rem;
  }
  .loginModal__submitBtn {
    width: 100%;
  }
  .loginBtn{
    width: 100%;
  }
}
