.phoneInput > input {
  border-radius: 5px;
  border-width: 1px;
  line-height: 39px;
  border-color: transparent;
  background: #f2f2f2;
  outline: none;
  border-style: solid;
}
.phoneInput > input:focus {
  border-color: #eb8aff;
}
.phoneInput > input:hover {
  border-color: #eb8aff;
}
.phoneInput.error > input {
    border-color: #ff4d4f;
  }
