.arrowBody {
  border: 1px solid #e1e8e8;
  border-radius: 12px !important;
  box-sizing: border-box;
}
.arrowBody:focus {
  color: black;
}
.arrowBody:hover svg > path {
  stroke: #f008;
}
