.carouselContainer {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.carouselContainerBlue {
  background: radial-gradient(
    99.16% 151.13% at 0.87% 1.25%,
    rgba(83, 138, 220, 0.1) 0%,
    rgba(41, 31, 155, 0.1) 100%
  );
}

.carouselContainerGold {
  background: radial-gradient(
    87.11% 149.32% at 1.02% 1.29%,
    rgba(210, 192, 128, 0.1) 0%,
    rgba(157, 138, 70, 0.1) 100%
  );
}

.carouselContainerBrown {
  background: radial-gradient(
    100.03% 152.44% at 0% 31.09%,
    rgba(153, 105, 33, 0.1) 0%,
    rgba(85, 53, 15, 0.1) 100%
  );
}

.carouselItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 53px;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  padding-left: 15px;
  margin-bottom: 15px;
}
.pBlue {
  color: #6a6696;
}
.pGold {
  color: #3a3a3a;
}
.pBrown {
  color: rgba(92, 63, 27, 0.79);
}

.title {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 5px;
  padding-left: 15px;
}

.subTitle {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 36px;
  padding-left: 15px;
  max-width: 437px;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img {
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  height: 240px !important;
}

.spanWrapper {
  margin-top: 117px;
  margin-right: 50px;
}

.spanDefault {
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.spanDefaultGold {
  background-color: #eae4ce;
}

.spanDefaultBlue {
  background-color: #dbdff2;
}

.spanDefaultBrown {
  background-color: #e4ddd3;
}

.spanDefault:not(:first-child) {
  margin-left: 20px;
}

.gold {
  background-color: #d0bd7d;
}

.blue {
  background-color: #4463c4;
}

.brown {
  background-color: #7c6346;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 2;
  }
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 2;
  }
}
