.payeeCard {
    width: 277px;
    border-radius: 5px;
}

.row__payeeCard {
    padding: 70px;
    padding-top: 30px;
}

.payeeCard__alignment_left {
    text-align: left;
}

.payeeCard .ant-card-head {
    margin-bottom: -10px;
}
.payeeCard__head__text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
}

.payeeCard__head-subText {
  color: rgba(0, 0, 0, 0.5);
}

.close-mobile-icon-container {
    visibility: hidden;
}

.payeeCard__head-icon {
    font-size: 9px;
    stroke: #000;
    stroke-width: 100px;
}

.payeeCard__head-icon-alignment {
    float: right;
}

.payeeCard__body {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: rgba(0, 0, 0, 0.5);
}

.payeeCard .ant-card-body  {
    padding: 0 24px 24px 24px;
}

.payeeCard__body .bankname, .payeeCard__body .bankaccount{
    line-break: anywhere;
}

@media screen and (max-width: 768px) {
    .row__payeeCard {
        padding: 10px;
        padding-top: 10px;
    }

    .payeeCard {
        width: 100%;
    }

    .payeeCard-col-sm-24 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .payeeCard__head-icon-alignment {
        float: none;
    }

    .close-mobile-icon-container {
        visibility: visible;
    }

    .payeeCard__head-visibility {
        visibility: hidden;
    }

    .payeeCard__head-icon {
        font-size: 20px;
        stroke: 60px;
    }
  }

  @media screen and (max-width: 440px) {
    .payeeCard__head__text {
        font-size: 11px;
    }

    .payeeCard__body .bankname, .payeeCard__body .bankaccount{
        font-size: 11px;
    }


  }

  @media screen and (max-width: 240px) {
    .payeeCard__head__text {
        font-size: 9px;
    }

    .payeeCard__body .bankname, .payeeCard__body .bankaccount{
        font-size: 9px;
    }

    .payeeCard__head-icon {
        font-size: 15px;
        stroke: 50px;
    }

  }
