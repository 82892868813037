.btn {
  height: 40px !important;
  min-width: 220px;
  border-radius: 5px !important;
  font-weight: 700 !important;
}

.btn .logoContainer {
  position: absolute;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  width: fit-content;
  top: 0;
  bottom: 0;
}

.disabled {
  background-color: white !important;
  border: none !important;
  color: #191a32 !important;
  box-shadow: none !important;
}

.primary {
  background-color: #191a32 !important;
  color: white !important;
  border: none !important;
}
.primary:hover {
  color: white !important;
  background-color: #191a32 !important;
  border: none !important;
}
.primary:focus {
  color: white !important;
  background-color: #191a32 !important;
  border: none !important;
}

.purple {
  background-color: #e062ff !important;
  box-shadow: 0px 15px 15px rgba(224, 98, 255, 0.25) !important;
  color: white !important;
  border: none !important;
}
.purple:hover {
  background-color: #e062ff !important;
  color: white !important;
}
.purple:focus {
  background-color: #e062ff !important;
  color: white !important;
}

.grey {
  background-color: #e0e0e0 !important;
  border: none !important;
  color: white !important;
}
.grey:hover {
  background-color: #e0e0e0 !important;
  color: white !important;
}
.grey:focus {
  background-color: #e0e0e0 !important;
  color: white !important;
}

.secondary {
  background-color: white !important;
  border: 2px solid black !important;
  color: #191a32 !important;
}
.secondary:hover {
  background-color: white !important;
  border: 2px solid black !important;
  color: #191a32 !important;
}
.secondary:focus {
  background-color: white !important;
  border: 2px solid black !important;
  color: #191a32 !important;
}
