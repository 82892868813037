.insights {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.insights .insights_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.insights .insights_head button {
  background: #ffffff;
  border: 1px solid #e1e8e8;
  box-sizing: border-box;
  border-radius: 15px;
  width: 51px;
  height: 51px;
}

.insights h1 {
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 26px;
  color: #4f4f4f;
}

.insights .skeleton {
  padding: 10px;
}

.insights .exchange_table {
  width: 100%;
}

.insights .exchange_table thead {
  border-bottom: 1px solid #e0e0e0;
}

.insights .exchange_table thead tr {
  display: flex;
  padding: 0 20px;
}

.insights .exchange_table thead tr th.stretch {
  flex-grow: 1;
  text-align: right;
  padding: 10px;
  margin-left: 20%;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 17px;
  color: #bb6bd9;
  border-bottom: 3px solid #bb6bd9;
}

.insights .exchange_table tbody tr {
  margin: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 5px;
  gap: 6px;
}

.insights .exchange_table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.insights .exchange_table tbody tr td .imageWrapper {
  margin: 13px 19px;
  padding: 6px;
  border: none;
  border-radius: 50%;
  background-color: white;
}

.insights .exchange_table tbody tr td .imageWrapper img {
  width: 39px;
}

.insights .exchange_table tbody tr td div {
  display: flex;
  flex-direction: column;
}

.insights .exchange_table tbody tr td div p {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 17px;
  color: #000000;
  text-align: left;
  margin-bottom: 0;
  white-space: nowrap;
}

.insights .exchange_table tbody tr td div small {
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  white-space: nowrap;
}

.insights .exchange_table tbody tr td.stretch {
  flex-grow: 1;
  margin-left: 20%;
  padding: 10px;
}

.insights .exchange_table tbody tr td.stretch div p,
.insights .exchange_table tbody tr td.stretch div small {
  text-align: right;
}

.insights .exchange_table thead tr th:first-child,
.insights .exchange_table tbody tr td:first-child {
  width: 91px;
}

.insights .exchange_table thead tr th:nth-child(2),
.insights .exchange_table tbody tr td:nth-child(2) {
  width: 200px;
}

.insights .paginator {
  display: flex;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-radius: 0px 0px 5px 5px;
  flex-grow: 1;
  align-items: flex-end;
}

.insights .paginator .dropdown {
  padding: 16px 20px;
  border-right: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.insights .paginator .dropdown button {
  border: none;
  background-color: transparent;
}

.insights .paginator .indicator {
  padding: 16px 25px;
}

.insights .paginator p {
  margin-bottom: 0;
  margin-right: 10px;
}

.content_card_header {
  border: 1px solid white;
  height: 88px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

@media only screen and (max-width: 992px) {
  .insights .exchange_table thead tr th:nth-child(2),
  .insights .exchange_table tbody tr td:nth-child(2) {
    width: 100px;
  }

  .insights .exchange_table thead tr th.stretch,
  .insights .exchange_table tbody tr td.stretch {
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .insights h1 .logo {
    display: none;
  }

  .insights .exchange_table thead tr th:first-child,
  .insights .exchange_table tbody tr td:first-child {
    width: auto;
  }
}

@media only screen and (max-width: 400px) {
  .insights .exchange_table tbody tr {
    margin: 5px;
  }

  .insights .exchange_table tbody tr td .imageWrapper {
    margin: 13px 5px;
  }
}
