.terms {
  height: 250px;
  width: calc(100% - 79px);
  margin-bottom: 40px;
  margin-right: 79px;
  overflow-y: scroll;
  text-align: justify;
}
.btnContainer {
  display: flex;
  align-items: flex-end;
}
.checkboxContainer {
  display: flex;
  align-items: center;
}
.termsDescriptionContainer {
  margin-left: 20px;
}
.form {
  display: flex;
  justify-content: space-between;
}
.termsDescription1 {
  margin-bottom: 2px;
}
.termsDescription2 {
  display: inline-block;
  max-width: 280px;
}
@media (max-width: 611px) {
  .form {
    flex-direction: column;
  }
}
@media (max-width: 427px) {
    .terms{
        margin-right: unset;
        width: 100%;
    }
  .btn {
      margin-top: 10px;
    width: 100%;
  }
}
