.header {
  line-height: 50px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 28px 30px 0px 30px;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 2.188rem;
  line-height: 2.625rem;
}
.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 1.875rem;
}
.title,
.subTitle {
  margin: 0;
}
.child {
  margin-top: 22px;
}
.formBodyClass {
  padding: 0 36px 50px 115px;
}
.formDescription {
  color: #4f4f4f;
}

@media (max-width: 427px) {
  .formBodyClass {
    padding: 0 36px 50px 36px;
  }
  .backArrow {
    left: -15px;
    margin-bottom: 21px;
  }
  .stepperContainer {
    display: none;
  }
}
