.paginator {
    display: flex;
    background: #FFFFFF;
    border-top: 1px solid #E0E0E0;
    border-radius: 0px 0px 5px 5px;
    flex-grow: 1;
    align-items: flex-end;
}

.paginator .dropdown {
    padding: 16px 20px;
    border-right: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
}

.paginator .dropdown button {
    border: none;
    background-color: transparent;
}

.paginator .indicator {
    padding: 16px 25px;
}

.paginator p {
    margin-bottom: 0;
    margin-right: 10px;
}
