.App {
  margin-top: 10%;
  text-align: center;
}

.textFont {
  color: #4f4f4f;
}

.titleFont {
  color: #4f4f4f;
  font-weight: 600;
}

.button {
  width: 75%;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  padding-top: 3px;
}

.otpContainer {
  margin: auto;
}

.otpInput {
  background: #f2f2f2;
  text-align: center;
  border: none;
  color: #E062FF;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 300px;
  padding-bottom: 3px;
}

.otpInput:valid {
  background: rgba(224, 98, 255, 0.3);
}
.otpInput:focus,
input:focus {
  outline: none;
}
.otpDiv {
  margin-bottom: 100px;
}
@media screen and (min-width: 601px) {
  .textFont {
    font-size: 14px;
  }
  .titleFont {
    font-size: 24px;
  }
  .otpInput {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
    font-size: 2rem;
  }

  .button {
    width: 25%;
  }
}

@media screen and (max-width: 786px) {
  .textFont {
    font-size: 12px;
  }
  .titleFont {
    font-size: 18px;
  }
  .otpInput {
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
  }
  .button {
    width: 75%;
  }
}
