.footer {
  background-color: #191a32;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  padding: 0 14.938rem 0 14.938rem;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.footerContainer {
  display: grid;
  column-gap: 30px;
  font-size: 0.75rem;
  max-width: 60.063rem;
  width: 100%;
  padding-bottom: 30px;
}
.footerDiv1 {
  grid-column: 1;
}
.footerDiv2 {
  grid-column: 2;
}
.footerDiv3 {
  grid-column: 3;
  text-align: center;
}
.footerLinks{
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 1428px) {
  .footer {
    padding: 0 10rem 0 10rem;
  }
}
@media (max-width: 1084px) {
    .footer{
        padding: 0 5rem 0 5rem;
    }
}

@media (max-width: 700px) {
  .footerContainer {
      display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    font-size: 1rem;
  }
  .footerDiv3,
  .footerDiv2,
  .footerDiv1 {
    margin-top: 0.625rem;
    text-align: center;
  }
}
@media (max-width: 425px) {
    .footer{
        padding: 0 2rem 0 2rem;
    }
  }
  