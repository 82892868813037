.signup {
  display: flex;
  height: 100%;
  justify-content: center;
}

.controls {
  padding: 30px;
}

@media (max-width: 768px) {
  .controls {
    padding: 0;
  }
}
