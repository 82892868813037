.formItem {
  width: 313px;
}
.formItem:last-child > div > div + div {
  display: none;
}
.formItem:last-child {
  margin-bottom: 0;
}
.formContainer2 {
  display: flex;
  align-items: flex-end;
}
.form {
  display: flex;
  justify-content: space-between;
  min-width: 313px;
}
.formBtn {
  width: 100%;
}

.createAccountBtnContainer {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 704px) {
  .form {
    flex-direction: column;
    width: unset;
    min-width: unset;
  }
  .formItem {
    max-width: 313px;
    width: unset;
  }
  .formContainer2 {
    margin-top: 20px;
  }
}
@media (max-width: 427px) {
  .formItem {
    max-width: unset;
    width: 100%;
  }
  .createAccountBtn {
    width: 100%;
  }
}
