.wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    max-width: 100%;
  }
  
  .label {
    color: #999CAD;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 14px;
  }
  
  .container {
    width: 100%;
    padding-inline: 3rem !important;
    padding-block: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .innerwrap {
    padding-top: 20%;
  }
  
  .subcaption,
  .caption {
    font-family: Sk-Modernist;
    font-style: normal;
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 400;
  }
  .subcaption {
    width: 13ch;
    padding-top: 8px;
  }
  .caption {
    font-weight: 700;
    font-size: 35px;
    line-height: 8px;
  }
  
  .inputBox {
    padding-top: 26px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .formbtn {
    padding-bottom: 1rem;
  }
  .formbtn .submitBtn,
  .formbtn .ant-btn {
    box-shadow: 0px 15px 15px rgba(107, 107, 107, 0.25) !important;
    border-radius: 10px !important;
    height: 50px !important;
    min-width: unset;
    width: 100%;
  }
  
  .signupLink {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #999cad;
  }
  
  .signupLink a {
    color: #999cad;
    text-decoration: underline;
  }

  @media only screen and (max-width: 786px) {
    .wrapper {
      height: auto;
    }
    
    .container {
      justify-content: center;
      padding-block: 0 !important;
      min-height: 0;
    }
  }

  @media only screen and (max-width: 320px) {
    .subcaption,
    .caption {
      font-size: 8vw;
    }

    .caption {
      font-size: 17vw;
      line-height: 33.3px;
    }
  }
