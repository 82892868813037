.bg {
    background-image: url("../../../assets/images/homeBg.png");

    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(25, 26, 50, 0.9);
  }

.auth__footer {
  position: fixed;
  z-index: 1001;
  bottom: 0;
}
@media (max-width: 427px) {
  .auth__footer{
      display: none;
  }
}
