.tdc {
  width: 100%;
  max-width: 375px;
  padding: 38px;
}
.tdc .cancel-icon {
  position: relative;
  top: -8px;
}
.tdc .header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.tdc .header > .ant-typography {
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
}
.tdc .container>ul {
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;
  padding-inline-start: 0;
}
.tdc .container>ul>li {
  display: flex;
  flex-flow: column;
  line-height: 14px;
  margin: 8px 0;
}
.tdc .container>ul>li>.label {
  margin-bottom: 6px;
}
.tdc .container>ul>li>.value {
  width: 100%;
  max-width: 295px;
}
.tdc .label {
    color: #999CAD;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 14px;
}
.tdc .defaultFormInput {
  margin-top: 30px;
}
.tdc .back-icon {
  display: none;
}
.detail > div.ant-modal-content {
  height: 100vh;
}

@media (max-width: 767px) {
  .tdc .back-icon {
    display: inline-block;
    position: relative;
    top: -4px;
    margin-right: 1em;
  }
  .tdc .cancel-icon {
    display: none;
  }
  .tdc .header {
    justify-content: flex-start;
  }
}