.authModal__authModal .ant-modal-content {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  overflow: hidden;
}

@media (max-width: 427px) {
  .ant-modal {
    max-width: 100vw !important;
    padding-bottom: 0;
  }
  .authModal__children {
    min-height: 79vh;
  }
  .ant-modal-centered {
    text-align: unset;
  }
  .ant-modal-centered::before {
    vertical-align: top;
  }
  .ant-modal.authModal__authModal {
    margin: 0;
    top: 0;
  }
  .authModal__authModal .ant-modal-content {
    box-shadow: none;
    border-radius: 0;
  }
}
@media (min-width: 428px) {
  .authModal__footer {
    display: none;
  }
}
