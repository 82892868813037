.balances {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border-radius: 5px;
  margin-bottom: 0;
  border: 2px solid #e0e0e0;
}
.balances_head {
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 0%;
}

.balances_border_vertical_last {
  border-right: 1px solid white;
  margin-top: 40px;
  margin-bottom: 40px;
}
.balance_table {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.balance_table thead {
  background-color: #f2f2f2;
  border: 1px solid #e0e0e0;
}
.balance_table thead tr {
  margin: 5px 10px;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 20px;
}
.balance_table tbody tr {
  margin: 5px 10px;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
}

.balance_table_section {
  margin-top: 20px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #e0e0e0;
}

.imageWrapper img {
  width: 39px;
}
.imageWrapperSmall img {
  width: 39px;
}

.skeleton {
  padding: 10px;
}

.available, .ledger {
  vertical-align: middle;
}

.available p, .ledger p {
  margin: auto;
}
.bottom_rectangle {
  position: relative;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 0px 0px 5px 5px;
  height: 28px;
}
.currencySymbol {
  margin-right: 5px;
}
.wholePart {
  font-weight: normal;
}
.fractionPart {
  color: gray;
}
.balance_table_headers {
  font-weight: lighter;
  color: gray;
}

@media screen and (min-width: 1001px) {
  .balances_title {
    font-weight: bold;
    font-size: 22px;
  }
  .balances_button_container {
    margin: 10px 20px;
    display: inline-block;
  }
  .balances_border_vertical {
    border-right: 1px solid #e0e0e0;
    margin: 40px 35px 20px 40px;
  }
  .balances_border {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .balances_sub_title {
    font-size: 18px;
    margin-right: 80px;
    margin-left: 60px;
  }
  .balances_amount {
    font-size: 25px;
    margin-right: 80px;
    margin-left: 60px;
  }
  .balances_topup_button {
    float: left;
  }

  .balances_withdraw_button {
    margin-left: 15px;
    float: right;
  }

  .balances_transfer_button {
    float: right;
  }
  .currency {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .currency_name {
    width: 100px;
    margin: 20px 3px;
  }

  .currency_symbol {
    width: 50px;
    margin: 20px 3px;
  }
  .imageWrapper {
    margin: 13px 5px;
    border: none;
    border-radius: 50%;
    background-color: white;
  }
  .imageWrapperSmall {
    border: none;
    border-radius: 50%;
    background-color: white;
    float: left;
    display: none;
  }
  .ledgerIcon {
    align-items: center;
    margin-right: 20px;
    margin-bottom: 15px;
  }
  .break {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .balances_title {
    font-weight: bold;
    font-size: 15px;
  }
  .balances_button_container {
    display: flex;
    margin: 10px;
    justify-content: space-between;
  }
  .balances_sub_title {
    font-size: 18px;
  }
  .balances_amount {
    font-size: 25px;
  }
  .balances_border_vertical {
    border-bottom: 1px solid #e0e0e0;
    margin: 40px 100px 0;
    padding-bottom: 20px;
  }
  .balances_border {
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
  }
  .balances_topup_button {
    border-radius: 5px;
    background-color: white;
    border: 1px solid;
  }

  .balances_withdraw_button {
    border-radius: 5px;
    background-color: black;
    color: white;
  }

  .balances_transfer_button {
    border-radius: 5px;
    color: white;
    background-color: black;
  }
  .currency {
    height: 100%;
    margin: 13px 5px 13px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .currency_symbol {
    margin-bottom: 0;
  }
  .imageWrapper {
    border: none;
    border-radius: 50%;
    background-color: white;
    float: left;
    display: none;
  }
  .imageWrapperSmall {
    margin: 13px 5px;
    border: none;
    border-radius: 50%;
    background-color: white;
    float: left;
  }
  .ledgerIcon {
    margin-bottom: 15px;
    margin-right: 20px;
  }
}
