.defaultFormInput{
    position: relative;
}
.defaultFormInput-password.ant-input-password, .defaultFormInput-input.ant-input {
    border: 0.5px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 15px;
    height: 50px;
}
.defaultFormInput-label {
    position: absolute;
    top: -10px;
    left: 20px;
    z-index: 1000;
    background: white;
    padding: 0 10px 0 10px;
}
.defaultFormInput-label--error {
    color: red;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
