.actionItemsContainer__addPayeeBtn{
    width: 30% ;
    margin: 10px;
    color: #F2F2F2;
    background-color: #333333 !important;
    font-size: 14px;
    border: 1px solid #000000 !important;
    border-radius: 3px !important;
}

.addPayeeBtn__text{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
}

.topnavbar{
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content:space-between
}

.topnavbar__payeeText{
    font-weight: bold;
    margin-left: 20px;
    font-size: 22px;
    line-height: 26px;
}

.payeemodal__inputbox{
    margin-bottom: 15px !important;
}

.payeemodal__Title{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #4F4F4F;
}

.payeemodal_mobileText{
    margin-top: 20px;
    margin: 20px;
    font-weight: bold;
    font-size: 22px;
    color: #000000;
}

.payeemodal_mobilesubText{
    margin-top: 30px;
    font-size: 14px;
    color: #4F4F4F;
}

.topnavbar__actionItemsContainer{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    margin-right: 10px;
}

.payeemodal__InstructionContainer{
    margin-bottom: 50px;
    margin-left: 2%;
}

.payeemodal__form{
    margin-left: 2%;
}

.payeemodal__Column{
    width: 40%;
    margin-right: 10%;
}

@media (min-width: 768px) and (max-width: 1020px) {
    .actionItemsContainer__addPayeeBtn{
        width: 40%;
    }
    .actionItemsContainer_searchbox{
        width: 60%;
        margin: 10px;
        border: 1px solid #4F4F4F;
        border-color: black;
        border-radius: 3px;
        background: #FFFFFF;
        height: 30px;
    }
}

@media (min-width: 1024px) {
    .actionItemsContainer_searchbox{
        width: 60% !important;
        margin: 10px;
        border: 1px solid #4F4F4F !important;
        border-color: black !important;
        border-radius: 3px !important;
        background: #FFFFFF;
        height: 30px;
    }
}


@media (max-width: 764px) {
    .topnavbar{
        background-color: orchid;
        background: #FFFFFF;
        width: 95%;
        border: 1px solid #E0E0E0;
        border-radius: 5px 5px 0px 0px;
        margin: 10px;
    }
    .topnavbar__payeeText{
        margin: 10px;
    }
    .actionItemsContainer__addPayeeBtn{
        width: 90%;
    }
    .actionItemsContainer_searchbox{
        width: 95% !important;
        border-radius: none;
        border: 1px solid #E0E0E0;
        height: 35px;
    }
}


