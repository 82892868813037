.regFormWrapper {
    height: 80vh;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    /* align-items: center; */
    position: relative;
    padding-top: 3rem;
    margin-top: 48px;
    width: 295px;
    max-width: 100%;
}
.regFormWrapper div[role="alert"] {
    font-size: 8px;
    text-align: center;
}
.posLeft {
    position: absolute !important;
    top: 0;
    left: 0;
}
.regTitle {
    margin: .5rem 0 1.5rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.75rem !important;
    line-height: 33.6px !important;
}
.terms {
    max-width: 100%;
    width: 295px;
    display: flex;
    place-content: center;
}
.terms>div {
    color: #999CAD;
}
.terms>div>a {
    color: #999CAD !important;
    text-decoration: underline;
}
.btn {
    padding: 15px 0 !important;
    height: initial !important;
    width: 295px;
    max-width: 100%;
}
.label {
    color: #999CAD;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 14px;
}

@media (max-width: 320px) {
    .regFormWrapper {
        width: 100%;
    }

    .regTitle {
        font-size: 6.5625vw !important;
        line-height: 20px !important;
    }

    .terms {
        width: 100%;
        font-size: 4.375vw;
    }

    .btn {
        width: 100%;
        min-width: auto;
        font-size: 4.375vw !important;
    }
}
