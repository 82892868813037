.defaultFormInput .defaultFormInput-input, .defaultFormInput .defaultFormInput-password {
    width: 295px;
}

@media (max-width: 768px) {
  .img-carousel-container {
      display: none;
  }

  .caption_ h1 {
    display: none;
  }

  .caption_:after {
    content: 'Theia';
    text-transform: uppercase;
    line-height: 42px;
    letter-spacing: 8px;
  }
}

@media (max-width: 320px) {
  .defaultFormInput .defaultFormInput-label {
    font-size: 2.8125vw !important;
  }

  .defaultFormInput .ant-input {
    font-size: 4.375vw !important;
  }
  
  .defaultFormInput .defaultFormInput-input, .defaultFormInput .defaultFormInput-password {
      width: 100%;
  }
}
