.faq_container {
    margin-bottom: 25px;
    text-align: start;
}

.support_btn {
    box-shadow: 0px 15px 15px rgba(107, 107, 107, 0.25) !important;
    border-radius: 10px !important;
    padding: 15px 0px;
    width: 100%;
    height: 47px !important;
    display: flex;
    justify-content: center;    
    align-items: center; 
    width: 100%;   
    margin-top: 100px;
}

.faq_btn {
    border: 1px solid #E1E8E8 !important;
    margin-left: 13px;
}

.accordion__icon {
    margin-right: 12px;
}

.accordion__title >h1.minimal__margin {    
    margin: 0px;
}

.header_bordered {
    background: #FFFFFF;
    border: 1px solid #F4F4F4 !important;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 20px;
}

.accordion__title>h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 31px;
    color: #000000;
    margin: 3rem 0 2rem;
}
.accordion__header{
    padding-left: 0;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 5px 0 10px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
}

.accordion__text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}
/*[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,*/
/*.site-collapse-custom-collapse .site-collapse-custom-panel{*/
/*    padding-left: 0;*/
/*}*/

.accordion__header> .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
}