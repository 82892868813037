.setPasswordContainer {
  padding-top: 60px;
  background-color: white;
  width: 100%;
  height: 100% !important;
}
.setPasswordWrapper {
  padding-top: 35px;
}
.headerText {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  padding-top: 50px;
}
.nextButton {
  width: 295px;
  border-radius: 10px !important;
}
.formItem {
  width: 295px;
}
@media (max-width: 704px) {
  .formItem {
    max-width: 295px;
    width: unset;
  }
  .nextButton {
    max-width: 295px;
    width: unset;
    border-radius: 10px !important;
  }
}
.passwordValidation {
  margin-left: 30px;
  padding-top: 26px;
}
