.sider_title {
    border: 1px solid #E5E5E5;
}

.sider_title {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}
