.count {
  font-size: 14px;
  font-weight: bold;
}

.count,
.table {
  text-align: left;
  max-width: 50vw;
  margin-left: 10em;
  margin-top: 2em;
}

.table {
  width: 100%;
  max-width: 50vw;
  margin: 2em auto;
}

.payment,
.table th,
.decimal {
  color: #989aa6;
}

.table th {
  font-size: 12px;
  font-weight: normal;
}

.table th:last-child {
  text-align: right;
}

.amount {
  text-align: right;
}
.container > nav {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #e1e8e8;
}
.container > nav > h4 {
  font-family: TT Norms Pro;
  font-weight: normal;
  font-size: 16px;
  margin: 0 0 0 1em;
}
.container .filter-btn {
  margin-left: auto;
}
.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.row:hover {
  background-color: #F8F0FB;
}
.row[data-active="true"] {
  background-color: #F8F0FB;
}
.column {
  margin: 10px 0;
}
.major {
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 17px;
}
.sub {
  display: block;
  text-align: start;
  color: #989AA6;
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
}

@media (max-width: 767px) {
  .container {
    padding: 0 1em;
  }
}