.arrowBody {
  border: 0 !important;
  border-radius: 0px;
}
.arrowBody:focus {
  color: black;
}
.arrowBody svg > rect:hover {
  stroke: #f008;
}
.icon {
  position: relative;
  border: 1px solid #e1e8e8;
  width: 38px;
  height: 37px;
  border-radius: 12px;
}
.icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.icon:hover svg,
.icon:hover path {
  fill: #f008;
}
