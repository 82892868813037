.search_btn {
    background: #F7F7F7;
    border: 1px solid #E1E8E8;
    box-sizing: border-box;
    border-radius: 10px;
}

.search_btn button {
    border-radius: 10px !important;
    color: #000;
    background: #fff;
    border: 1px solid #E1E8E8;
}
