.container{
    padding-top: 30px
}

.back{
    border: 1px solid #E1E8E8 !important;
    border-radius: 12px !important;
    margin-bottom: 30px
}

.accept{
    font-size: 24px;
    font-family: Sk-Modernist;
}