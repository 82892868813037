.transaction {
  display: flex;
  height: 100%;
}
.transaction > div {
  width: 62vw;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}
.transaction tr:hover {
  background-color: #F8F0FB;
  cursor: pointer;
}
.transaction tr[data-active="true"] {
  background-color: #F8F0FB;
}
.transaction table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
.transaction aside {
  padding: 30px;
}

@media (max-width: 767px) {
  .transaction > div {
    width: 100%;
  }
}
