.top_bar_header, .left_sider, .content_card, .top_bar_header__mobile {
    border: 1px solid #E5E5E5;
}

.left_sider {
    position: relative;
    background: #fff;

}

.top_menu, .bottom_menu {
    background: transparent;
}

.top_menu {
    margin-top: -5.1px;
}

.bottom_menu {
    position: absolute;
    bottom: 0px;
}
.top_bar_header, .top_bar_header__mobile{
    background: #fff;
    font-family: Sk-Modernist;
    color: #333333;
}

.top_bar_header{
    height: 50px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white !important;
}


.top_bar_header h1 {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 1.39%;
    right: 87.43%;
    font-size: 30px;
    line-height: 36px;
    font-style: normal;
    font-weight: bold;

}

.top_bar_header p {
    position: absolute;
    width: 194px;
    height: 18px;
    right: 26px;
    top: 16px;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;



}

.site_layout, section.site_layout {
    background-color: #fff;
}

.content_layout {
    background-color: white;
    margin: 8px 16px;
    border-radius: 5px;
    border: none;
}
.content_card_header {
    border: 1px solid #E5E5E5;
    height: 88px;
}

.collapse__trigger {
    font-size: 18px;
}

.menu_horizontal {
    padding: 0;
    margin: 0;
    display: flex;
    text-align: left;
    line-height: 15px;
    font-size: 18px;
    outline: none;
    list-style: none;
    font-weight: bold;
}

.menu_horizontal li {
    margin-bottom: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
}

.menu_horizontal li:nth-child(2) {
    padding: 0 20px;
}

.top_bar_header__mobile .menu_horizontal h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #000000;
}

