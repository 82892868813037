
.back_btn {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 20px;
  top: 20px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}
.formItem {
  max-width: 313px;
}
@media (max-width: 427px) {
  .btn,
  .btnFormItem {
    width: 100%;
  }
}
